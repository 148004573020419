import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import SuspenseLoader from './suspenseLoader';
import './App.css';

const theme = createTheme();

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader addSx={{ mt: 5}}/>}>
			<Component {...props} />
		</Suspense>
	);

const Navbar = Loader(lazy(() => import('./components/Navbar/Navbar')));
const Home = Loader(lazy(() => import('./components/Home/Home')));
const Service = Loader(lazy(() => import('./components/Service/Service')));
const MeetingRoom = Loader(lazy(() => import('./components/Room/MeetingRoom')));
const ImageGallery = Loader(lazy(() => import('./components/Gallery/ImageGallery')));
const Footer = Loader(lazy(() => import('./components/Footer/Footer')));
const BedroomDetail = Loader(lazy(() => import('./components/Room/BedroomDetail')));
const GetInTouch = Loader(lazy(() => import('./components/DesignCard/GetInTouch')));


const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Navbar />
              <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/home' element={<Home/>}/>
                <Route path='/service' element={<Service />}/>
                <Route path='/meeting-room' element={<MeetingRoom/>}/>
                <Route path='/bedroom' element={<BedroomDetail/>}/>
                <Route path='/gallery' element={<ImageGallery/>}/>
                <Route path='/contact-us' element={<GetInTouch/>}/>
              </Routes>
              <Footer/>    
            </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
      <div className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}>
        <Fab size="medium" color="info" onClick={scrollToTop} >
          <UpIcon  />
        </Fab>
      </div>
    </>
  )
}
export default App